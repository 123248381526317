import React, { useEffect } from "react";
import { DispatchProPlan } from "../../../entities/admin/DispatchProPlan";
import { FetchOptionalResult } from "../../../services/server/WebClient";
import { BusinessService } from "../../../services/business/BusinessService";
import OverlayLoadingScreen from "../../../components/OverlayLoadingScreen";
import { ServerErrorView } from "../../../components/ServerErrorView";
import {
	Button,
	Card,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Grid,
	List,
	ListItem,
	ListItemText,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Typography,
} from "@material-ui/core";
import { runningOnProd } from "../../../AppEnv";
import { FinixForm, FinixJsClient } from "../../../utility/FinixJsClient";
import { useAlert } from "../../../hooks/useAlert";

export function PlanPage() {
	const [fetchResult, setFetchResult] = React.useState<FetchOptionalResult<DispatchProPlan>>();

	React.useEffect(() => {
		async function fetchPlan() {
			const result = await BusinessService.getPlan();
			setFetchResult(result);
		}
		fetchPlan();
	}, []);

	if (!fetchResult) {
		return <OverlayLoadingScreen />;
	}

	if (fetchResult.type === "error") {
		return <ServerErrorView serverError={fetchResult} />;
	}

	if (fetchResult.type === "missing") {
		return (
			<>
				<Typography variant="h1">Your plan has not been configured yet.</Typography>
				<Typography variant="h1">Please contact us for automated billing.</Typography>
			</>
		);
	}

	return <PlanView plan={fetchResult.data} />;
}

function PlanView(props: { plan: DispatchProPlan }) {
	const [plan, setPlan] = React.useState(props.plan);

	const [showPaymentMethodDialog, setShowPaymentMethodDialog] = React.useState(false);

	const onPaymentMethodUpdate = (plan: DispatchProPlan) => {
		setPlan(plan);
		setShowPaymentMethodDialog(false);
	}

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Typography variant="h1">{plan.name}</Typography>
				<Typography variant="h5">{plan.description}</Typography>
			</Grid>
			<Grid item md={4} xs={12}>
				<List>
					<ListItem>
						<ListItemText primary="Started" secondary={plan.startDate.toLocaleDateString()} />
						<ListItemText primary="Renews" secondary={plan.nextChargeDate.toLocaleDateString()} />
					</ListItem>
					<ListItem button onClick={() => setShowPaymentMethodDialog(true)}>
						<ListItemText primary="Payment Method" secondary={plan.paymentMethodDescription ?? "Click here to setup"} />
					</ListItem>
				</List>
			</Grid>

			<Grid item md={4} xs={12}>
				<Typography variant="h6">Included Items</Typography>
				<TableContainer component={Card}>
					<Table size="small">
						<TableBody>
							{plan.lineItems.map((item, index) => (
								<TableRow key={index}>
									<TableCell>{item.description}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Grid>
			<AddPaymentMethodDialog open={showPaymentMethodDialog} onClose={() => setShowPaymentMethodDialog(false)} onUpdate={onPaymentMethodUpdate} />
		</Grid>
	);
}

const formId = "dispatchpro-bank-form";

function AddPaymentMethodDialog(props: { open: boolean; onClose: () => void, onUpdate: (plan: DispatchProPlan) => void }) {
	const alert = useAlert();
	const [formHasErrors, setFormHasErrors] = React.useState(true);
	const [loading, setLoading] = React.useState(false);

	const finixForm = React.useRef<FinixForm | null>(null);

	useEffect(() => {
		if (props.open) {
			setTimeout(() => {
				const form = FinixJsClient.mountBankTokenForm(formId, {
					labels: {
						name: "Account Holder Name",
						bank_code: "Routing Number",
					},
					onUpdate: (_state, _binInformation, formHasErrors) => {
						setFormHasErrors(formHasErrors);
					},
				});
				finixForm.current = form;
			}, 700);
		}
	}, [props.open]);

	const onFormSubmit = () => {
		const form = finixForm.current;
		if (!form) return;

		const env = runningOnProd() ? "live" : "sandbox";
		const applicationId = runningOnProd() ? "APi9DbEiv5X6gqGx6ePt8pkj" : "AP5ePipiAXNbAPh42BCxr1mC";
		form.submit(env, applicationId, async (err, res) => {
			if (err) {
				console.error(err);
				return;
			}
			const token = res.data?.id;
			if (token) {
				setLoading(true);
				const result = await BusinessService.setPlanBankAccount(token);
				setLoading(false);
				if (result.success) {
					props.onUpdate(result.data);
				} else if (result.validation) {
					alert.validation(result);
				} else {
					alert.serverError(result);
				}
			}
		});
	};

	const onClose = () => {
		if (loading) return;
		props.onClose();
	};

	return (
		<Dialog open={props.open} onClose={onClose}>
			<DialogTitle>Link Bank Account</DialogTitle>
			<form
				onSubmit={(e) => {
					e.preventDefault();
					onFormSubmit();
				}}
			>
				<DialogContent>
					<DialogContentText>By linking your bank account, you agree that we may charge your account for any future balances due.</DialogContentText>
					<div id={formId} />
				</DialogContent>
				<DialogActions>
					<Button variant="outlined" onClick={onClose} disabled={loading}>
						Cancel
					</Button>
					<Button variant="contained" color="primary" type="submit" disabled={loading || formHasErrors}>
						Add Bank
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
}
