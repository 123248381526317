import { SerializedTaxExemption, TaxExemption, deserializeTaxExemptions } from "../../entities/accounting/TaxExemption";
import { PaymentTerms } from "../../entities/billing/PaymentTerms";
import { Customer, deserializeCustomer, SerializedCustomer } from "../../entities/customer/Customer";
import { CustomerContract, SerializedCustomerContract, deserializeCustomerContracts } from "../../entities/customer/CustomerContract";
import { deserializeCustomerAccountNote, deserializeCustomerNotes } from "../../entities/customer/CustomerNotes";
import { CustomerDocument, deserializeCustomerDocument, deserializeCustomerDocuments, SerializedCustomerDocument } from "../../entities/customer/CustomerDocument";
import { GeocodedAddress } from "../../entities/customer/GeocodedAddress";
import { PhoneNumber } from "../../entities/customer/PhoneNumber";
import { Tag } from "../../entities/customer/Tag";
import { ProductCategoryType } from "../../entities/products/ProductCategoryType";
import { OfflineRequestManager } from "../../providers/OfflineRequestManager";
import { NodaTime } from "../../utility/NodaTime";
import { OptionalValue, toOptional } from "../../utility/OptionalValue";
import { apiServer } from "../server/Setting";
import { WebClient } from "../server/WebClient";
import { CustomerTank, SerializedCustomerTank, deserializeCustomerTanks } from "./PropaneTankService";

interface CreateCustomerRequest {
	name: string;
	phoneNumber: string | null;
	phoneNumberExtension: string | null;
	address: GeocodedAddress;
}

interface UpdateCustomerRequest {
	customerId: string;
	name?: string;
	phoneNumber?: PhoneNumber | null;
	phoneNumberSecondary?: PhoneNumber | null;
	email?: string | null;
	emailSecondary?: string | null;
	allowSendEmail?: boolean;
	inactive?: boolean;
	alert?: string | null;
}

interface SerializedUpdateCustomerRequest {
	customerId: string;
	name?: string;
	phoneNumber?: OptionalValue<string | null>;
	phoneNumberExtension?: OptionalValue<string | null>;
	phoneNumberSecondary?: OptionalValue<string | null>;
	phoneNumberSecondaryExtension?: OptionalValue<string | null>;
	email?: OptionalValue<string | null>;
	emailSecondary?: OptionalValue<string | null>;
	inactive?: boolean;
	alertValue?: OptionalValue<string | null>;
}

interface UpdateCustomerAddressRequest {
	customerId: string;
	googleMapsPlaceId?: string;
	street: string;
	city: string;
	state: string;
	postalCode: string;
	county: string;
	latitude: number;
	longitude: number;
}

export interface UpdateDeliveryInstructionsRequest {
	customerId: string;
	deliveryInstructions: string | null;
}

export interface SearchCustomersRequest {
	excludeInactive?: boolean;
	hasOpenTicket?: boolean;
}

export interface SearchableCustomer {
	customerId: string;
	customerCode: string;
	name: string;
	address: GeocodedAddress;
	phoneNumber: PhoneNumber | null;
	balance: number;
	inactive: boolean;
	tags: Tag[];
}

function serializeUpdateCustomerRequest(request: UpdateCustomerRequest): SerializedUpdateCustomerRequest {
	const phoneNumber = request.phoneNumber === undefined ? undefined : request.phoneNumber?.number ?? null;
	const phoneNumberExtension = request.phoneNumber === undefined ? undefined : request.phoneNumber?.extension ?? null;
	const phoneNumberSecondary = request.phoneNumberSecondary === undefined ? undefined : request.phoneNumberSecondary?.number ?? null;
	const phoneNumberSecondaryExtension = request.phoneNumberSecondary === undefined ? undefined : request.phoneNumberSecondary?.extension ?? null;

	return {
		...request,
		phoneNumber: toOptional(phoneNumber),
		phoneNumberExtension: toOptional(phoneNumberExtension),
		phoneNumberSecondary: toOptional(phoneNumberSecondary),
		phoneNumberSecondaryExtension: toOptional(phoneNumberSecondaryExtension),
		email: toOptional(request.email),
		emailSecondary: toOptional(request.emailSecondary),
		alertValue: toOptional(request.alert),
	};
}

export interface LastSaleItem {
	id: string;
	productListingId: string;
	productLine: ProductCategoryType;
	productListingName: string;
	description: string;
	quantity: number;
	price: number;
	discount: number;
}

export interface LastSale {
	invoiceId: string;
	invoiceCode: string;
	timeOfSale: Date;
	items: LastSaleItem[];
}

type SerializedLastSale = Omit<LastSale, "timeOfSale"> & { timeOfSale: string };
function deserializeLastSale(model: SerializedLastSale | null): LastSale | null {
	if (!model) return null;
	return {
		...model,
		timeOfSale: new Date(model.timeOfSale),
	};
}

export interface CustomerProfile {
	customer: Customer;
	paymentTerms: PaymentTerms;
	taxExemptions: TaxExemption[];
	tanks: CustomerTank[];
	contracts: CustomerContract[];
	documents: CustomerDocument[];
}

interface SerializedCustomerProfile {
	customer: SerializedCustomer;
	paymentTerms: PaymentTerms;
	taxExemptions: SerializedTaxExemption[];
	tanks: SerializedCustomerTank[];
	contracts: SerializedCustomerContract[];
	documents: SerializedCustomerDocument[];
}

function deserializeCustomerProfile(model: SerializedCustomerProfile): CustomerProfile {
	return {
		customer: deserializeCustomer(model.customer),
		paymentTerms: model.paymentTerms,
		taxExemptions: deserializeTaxExemptions(model.taxExemptions),
		tanks: deserializeCustomerTanks(model.tanks),
		contracts: deserializeCustomerContracts(model.contracts),
		documents: deserializeCustomerDocuments(model.documents),
	};
}

export const CustomerService = {
	get: (customerId: string) => WebClient.GetOptional(`${apiServer}/api/customers/${customerId}`, deserializeCustomer),
	getByCode: (customerCode: string) =>
		WebClient.GetOptional(`${apiServer}/api/customers/code/${customerCode}`, (response: SerializedCustomer | null) => (response ? deserializeCustomer(response) : null)),
	getNotes: (customerId: string) => WebClient.GetOptional(`${apiServer}/api/customers/${customerId}/notes`, deserializeCustomerNotes),
	getLastSale: (customerId: string) => WebClient.GetOptional(`${apiServer}/api/customers/${customerId}/last-sale`, deserializeLastSale),
	getProfile: (customerId: string) => WebClient.GetOptional(`${apiServer}/api/customers/${customerId}/profile`, deserializeCustomerProfile),
	search: (request: SearchCustomersRequest) => WebClient.Post.Unvalidated<SearchableCustomer[]>(`${apiServer}/api/customers/search`, request),
	create: (request: CreateCustomerRequest) => WebClient.Post.Validated(`${apiServer}/api/customers`, request, deserializeCustomer),
	update: (request: UpdateCustomerRequest) => WebClient.Put.Validated(`${apiServer}/api/customers`, serializeUpdateCustomerRequest(request), deserializeCustomer),
	updateAddress: (request: UpdateCustomerAddressRequest) => WebClient.Put.Validated(`${apiServer}/api/customers/address`, request, deserializeCustomer),
	updateCode: (request: { customerId: string; customerCode: string }) => WebClient.Put.Validated(`${apiServer}/api/customers/code`, request, deserializeCustomer),
	updateDeliveryInstructions: (request: UpdateDeliveryInstructionsRequest) =>
		WebClient.Put.Validated(`${apiServer}/api/customers/delivery-instructions`, request, deserializeCustomer),
	canRemove: (customerId: string) => WebClient.GetOptional<boolean>(`${apiServer}/api/customers/can-remove/${customerId}`),
	remove: (customerId: string) => WebClient.Put.Validated(`${apiServer}/api/customers/remove/${customerId}`, {}),
	downloadStatement: (request: { customerId: string; startDate: Date; endDate: Date; markAsSent: boolean }, fileName: string) =>
		WebClient.Download.Post(
			`${apiServer}/api/customers/statement`,
			{ ...request, startDate: NodaTime.serializeToLocalDate(request.startDate), endDate: NodaTime.serializeToLocalDate(request.endDate) },
			fileName,
			"application/pdf"
		),
	addAccountNote: (request: { customerId: string; note: string }) =>
		WebClient.Post.Validated(`${apiServer}/api/customers/${request.customerId}/notes`, { note: request.note }, deserializeCustomerAccountNote),
	removeNote: (noteId: string) => WebClient.Delete(`${apiServer}/api/customers/notes/${noteId}`),
	uploadDocument: (customerId: string, file: File) => {
		const formData = new FormData();
		formData.append("file", file);
		return WebClient.Post.Validated(`${apiServer}/api/customers/${customerId}/documents`, formData, deserializeCustomerDocument);
	},
	updateDocument: (request: { id: string; name?: string }) => WebClient.Put.Validated(`${apiServer}/api/customers/documents`, request, deserializeCustomerDocument),
	removeDocument: (documentId: string) => WebClient.Delete(`${apiServer}/api/customers/documents/${documentId}`),
	downloadDocument: (document: CustomerDocument) => WebClient.Download.Get(`${apiServer}/api/customers/documents/${document.id}`, document.name, document.contentType),
	offline: {
		updateDeliveryInstructions: (offlineManager: OfflineRequestManager, request: UpdateDeliveryInstructionsRequest) => {
			offlineManager.addRequestToQueue({
				id: `UpdateDeliveryInstructions-${request.customerId}`,
				method: "PUT",
				name: "Update Delivery Instructions",
				payload: request,
				url: `${apiServer}/api/customers/delivery-instructions`,
			});
		},
	},
};
